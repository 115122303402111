<template>
  <div class="">
    <div class="row">
      <div class="col-12 col-md-5">
        <documents-send-mail-form
          :documentData="purchasesInvoice"
          :dispatchUrl="'purchasesInvoices/sendMail'"
          :toggleMailModal="toggleMailModal"
          :mailInformation="mailInformation"
        />
      </div>
      <div
        class="col-12 col-md-7"
        style="max-height: 550px; overflow-y: scroll"
      >
        <purchases-invoice-view-details
          :showHeader="false"
          :purchasesInvoice="purchasesInvoice"
          @purchasesInvoiceItemsUpdated="get"
        />
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <el-button type="danger" @click="toggleMailModal">
        {{ $t("COMMON.CANCEL") }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { Button, Option, Select } from "element-ui";
import "flatpickr/dist/flatpickr.css";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import DocumentsSendMailForm from "@/components/DocumentsSendMailForm";
import PurchasesInvoiceViewDetails from "./PurchasesInvoiceViewDetails";

export default {
  layout: "DashboardLayout",
  name: "sales-order-mail-modal-content",

  components: {
    DocumentsSendMailForm,
    PurchasesInvoiceViewDetails,
    [Button.name]: Button,
  },

  mixins: [formMixin],
  props: {
    purchasesInvoice: {
      default: () => {},
    },
    toggleMailModal: {
      type: Function,
    },
    get: {
      type: Function,
    },
    loading: {
      default: () => true,
    },
  },

  data() {
    return {
      submitting: false,
      mailInformation: {
        organization: {},
        recipient: {},
        user: {},
      },
    };
  },

  created() {},

  mounted() {
    this.mailInformation = {
      organization: this.purchasesInvoice?.organization,
      subject: this.$t("COMMON.EMAIL_INFOS_SUBJECT_INVOICE", {
        organization: this.purchasesInvoice?.organization?.name,
        code: this.purchasesInvoice.code,
      }),
      recipient: {
        username: this.purchasesInvoice?.issuer?.customer_name,
        email: this.purchasesInvoice?.issuer?.email,
      },
    };
  },

  methods: {},

  watch: {
    purchasesInvoice(purchasesInvoice) {
      this.mailInformation = {
        organization: purchasesInvoice.organization,
        subject: this.$t("COMMON.EMAIL_INFOS_SUBJECT_INVOICE", {
          organization: purchasesInvoice.organization?.name,
          code: purchasesInvoice.code,
        }),
        recipient: {
          username: purchasesInvoice?.issuer?.customer_name,
          email: purchasesInvoice?.issuer?.email,
        },
      };
    },
  },
};
</script>
