<template>
  <div class="">
    <div class="row">
      <div class="col-12 col-md-5">
        <documents-send-mail-form
          :documentData="purchasesInvoice"
          :dispatchUrl="'purchasesInvoices/sendPaymentMail'"
          :toggleMailModal="toggleMailModal"
          :mailInformation="mailInformation"
        />
      </div>
      <div
        class="col-12 col-md-7 bg-white card"
        style="max-height: 600px; overflow-y: scroll"
      >
        <purchases-invoice-view-details-purchases-invoice-item-table
          :purchasesInvoice="purchasesInvoice"
        />
        <div class="row mb-5" v-if="purchasesInvoice.pricing">
          <div class="col-6"></div>
          <div class="col-6">
            <dl class="row mb-0">
              <dt class="col-sm-6">{{ $t("COMMON.SUBTOTAL") }}</dt>
              <dd class="col-sm-6 text-right">
                {{ $formatCurrency(purchasesInvoice.pricing.subtotal) }}
              </dd>
            </dl>

            <dl
              class="row mb-0"
              v-if="purchasesInvoice.pricing.discounts.total"
            >
              <dt class="col-sm-6">{{ $t("COMMON.DISCOUNTS") }}</dt>
              <dd class="col-sm-6 text-right">
                {{
                  $formatCurrency(purchasesInvoice.pricing.discounts.total * -1)
                }}
              </dd>
              <div class="col-12">
                <dl
                  class="row mb-0"
                  v-for="(discount, index) in purchasesInvoice.pricing.discounts
                    .details"
                  v-bind:key="index"
                >
                  <dt class="col-sm-6 pl-5 text-muted">{{ discount.name }}</dt>
                  <dd class="col-sm-6 text-right text-muted">
                    {{ $formatCurrency(discount.amount) }}
                  </dd>
                </dl>
              </div>
            </dl>

            <dl class="row mb-0" v-if="purchasesInvoice.pricing.taxes.total">
              <dt class="col-sm-6">{{ $t("COMMON.TAXES") }}</dt>
              <dd class="col-sm-6 text-right">
                {{ $formatCurrency(purchasesInvoice.pricing.taxes.total) }}
              </dd>
              <div class="col-12">
                <dl
                  class="row mb-0"
                  v-for="(tax, index) in purchasesInvoice.pricing.taxes.details"
                  v-bind:key="index"
                >
                  <dt class="col-sm-6 pl-5 text-muted">{{ tax.name }}</dt>
                  <dd class="col-sm-6 text-right text-muted">
                    {{ $formatCurrency(tax.amount) }}
                  </dd>
                </dl>
              </div>
            </dl>

            <dl class="row mb-0">
              <dt class="col-sm-6">{{ $t("COMMON.TOTAL") }}</dt>
              <dd class="col-sm-6 text-right">
                {{ $formatCurrency(purchasesInvoice.pricing.total) }}
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <el-button type="danger" @click="toggleMailModal">
        {{ $t("COMMON.CANCEL") }}
      </el-button>
    </div>
  </div>
</template>
<script>
import { Button, Option, Select } from "element-ui";
import "flatpickr/dist/flatpickr.css";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import DocumentsSendMailForm from "@/components/DocumentsSendMailForm";
import PurchasesInvoiceViewDetails from "./PurchasesInvoiceViewDetails";
import PurchasesInvoiceViewDetailsPurchasesInvoiceItemTable from "./PurchasesInvoiceViewDetailsPurchasesInvoiceItemTable";

export default {
  layout: "DashboardLayout",
  name: "sales-order-mail-modal-content",

  components: {
    DocumentsSendMailForm,
    PurchasesInvoiceViewDetailsPurchasesInvoiceItemTable,
    [Button.name]: Button,
  },

  mixins: [formMixin],
  props: {
    purchasesInvoice: {
      default: () => {},
    },
    toggleMailModal: {
      type: Function,
    },
    get: {
      type: Function,
    },
    loading: {
      default: () => true,
    },
  },

  data() {
    return {
      submitting: false,
      mailInformation: {
        organization: {},
        recipient: {},
        user: {},
      },
    };
  },

  created() {},

  mounted() {
    this.mailInformation = {
      organization: this.purchasesInvoice?.organization,
      subject: this.$t("COMMON.EMAIL_INFOS_SUBJECT_PAYMENT", {
        organization: this.purchasesInvoice?.organization?.name,
        code: this.purchasesInvoice.code,
      }),
      recipient: {
        username: this.purchasesInvoice?.issuer?.customer_name,
        email: this.purchasesInvoice?.issuer?.email,
      },
    };
  },

  methods: {},

  watch: {
    purchasesInvoice(purchasesInvoice) {
      this.mailInformation = {
        organization: purchasesInvoice.organization,
        subject: this.$t("COMMON.EMAIL_INFOS_SUBJECT_PAYMENT", {
          organization: purchasesInvoice.organization?.name,
          code: purchasesInvoice.code,
        }),
        recipient: {
          username: purchasesInvoice?.issuer?.customer_name,
          email: purchasesInvoice?.issuer?.email,
        },
      };
    },
  },
};
</script>
