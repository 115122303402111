var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-8"},[_c('span',{staticClass:"display-4 col-12"},[_vm._v(_vm._s(_vm.$t("COMMON.PAYMENTS")))]),(
          _vm.purchasesInvoice.status !== _vm.INVOICE_STATUS_DRAFT ||
          _vm.purchasesInvoice.status === _vm.INVOICE_STATUS_DRAFT ||
          _vm.purchasesInvoice.items?.length
        )?_c('base-button',{attrs:{"type":"info","icon":"","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.print()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fa fa-file-pdf"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.PRINT"))+" ")])]):_vm._e(),(
          _vm.purchasesInvoice.status !== _vm.INVOICE_STATUS_DRAFT ||
          _vm.purchasesInvoice.status === _vm.INVOICE_STATUS_DRAFT ||
          _vm.purchasesInvoice.items?.length
        )?_c('base-button',{attrs:{"type":"success","icon":"","size":"sm","disabled":_vm.loading},on:{"click":function($event){return _vm.toggleMailModal()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fa fa-envelope"}),_vm._v(" "+_vm._s(_vm.$t("COMMON.SEND"))+" ")])]):_vm._e()],1)]),_c('purchases-payment-list-table',{attrs:{"filterInvoice":_vm.purchasesInvoice.id}}),_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-6"}),_c('div',{staticClass:"col-6"},[_c('dl',{staticClass:"row mb-0"},[_c('dt',{staticClass:"col-sm-6"},[_vm._v(_vm._s(_vm.$t("COMMON.TOTAL_PAIED")))]),_c('dd',{staticClass:"col-sm-6 text-right"},[_vm._v(" "+_vm._s(_vm.purchasesInvoice.total_paied !== undefined ? _vm.$formatCurrency(_vm.purchasesInvoice.total_paied) : null)+" ")])])])]),_c('modal',{attrs:{"show":_vm.showMailModal,"modal-classes":"modal-secondary","size":"xl"}},[_c('purchase-payment-mail-modal-content',{attrs:{"purchasesInvoice":_vm.purchasesInvoice,"toggleMailModal":_vm.toggleMailModal}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }