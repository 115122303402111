<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">{{ $t("COMMON.PAYMENTS") }}</span>

        <base-button
          type="info"
          icon
          size="sm"
          @click="print()"
          :disabled="loading"
          v-if="
            purchasesInvoice.status !== INVOICE_STATUS_DRAFT ||
            purchasesInvoice.status === INVOICE_STATUS_DRAFT ||
            purchasesInvoice.items?.length
          "
        >
          <span class="btn-inner--icon">
            <i class="fa fa-file-pdf"></i>
            {{ $t("COMMON.PRINT") }}
          </span>
        </base-button>

        <base-button
          type="success"
          icon
          size="sm"
          @click="toggleMailModal()"
          :disabled="loading"
          v-if="
            purchasesInvoice.status !== INVOICE_STATUS_DRAFT ||
            purchasesInvoice.status === INVOICE_STATUS_DRAFT ||
            purchasesInvoice.items?.length
          "
        >
          <span class="btn-inner--icon">
            <i class="fa fa-envelope"></i>
            {{ $t("COMMON.SEND") }}
          </span>
        </base-button>
      </div>
    </div>

    <purchases-payment-list-table :filterInvoice="purchasesInvoice.id" />

    <div class="row mb-5">
      <div class="col-6"></div>
      <div class="col-6">
        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.TOTAL_PAIED") }}</dt>
          <dd class="col-sm-6 text-right">
            {{
              purchasesInvoice.total_paied !== undefined
                ? $formatCurrency(purchasesInvoice.total_paied)
                : null
            }}
          </dd>
        </dl>
      </div>
    </div>

    <modal :show="showMailModal" modal-classes="modal-secondary" size="xl">
      <purchase-payment-mail-modal-content
        :purchasesInvoice="purchasesInvoice"
        :toggleMailModal="toggleMailModal"
      />
    </modal>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import PurchasesPaymentListTable from "../../PurchasesPaymentManagement/partials/PurchasesPaymentListTable.vue";
import PurchasePaymentMailModalContent from "./PurchasePaymentMailModalContent";
import { INVOICE_STATUS_DRAFT } from "@/constants/invoices";
import swal from "sweetalert2";

export default {
  name: "purchases-invoice-view-details",

  components: {
    PurchasePaymentMailModalContent,
    PurchasesPaymentListTable,
  },

  mixins: [formMixin],

  props: ["purchasesInvoice"],

  data() {
    return {
      INVOICE_STATUS_DRAFT,
      loading: false,
      showMailModal: false,
    };
  },

  computed: {},

  methods: {
    toggleMailModal() {
      this.showMailModal = !this.showMailModal;
    },
    async print() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "purchasesInvoices/paymentPrint",
          this.purchasesInvoice.id
        );

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        window.open(url, "_blank");
        swal.close();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        swal.close();
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
